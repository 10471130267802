import {
    getCurrentPage,
    getPageHashtag,
    isFilterInPathActive,
    isRoomRoomlistSpaActive,
} from "@multimediallc/cb-roomlist-prefetch"
import { loadRoomRequest } from "../../../common/fullvideolib/userActionEvents"
import { dom, Fragment } from "../../../common/tsxrender/dom"
import { parseQueryString } from "../../../common/urlUtil"
import { RoomListSource } from "../../roomList"
import { RoomFollowStar } from "../followStar"
import { PaginatedApiRoomsIterator } from "./paginatedAPIRoomsIterator"
import { createPlaceholderCard, RoomCard } from "./roomCard"
import { RoomList } from "./roomList"
import { shouldShowJoinOverlay } from "./spaHelpers"
import type { IRoomInfo } from "./IRoomInfo"
import type { IRoomCardProps } from "./roomCard"
import type { IRoomClickDetails, IRoomListProps } from "./roomList"
import type { RoomSourceOptionalFields } from "../../roomList"
import type { IRoomListAPIParams } from "@multimediallc/cb-roomlist-prefetch";


const DEFAULT_NUM_PLACEHOLDER_CARDS = 90
interface IApiRoomlistProps extends Omit<IRoomListProps, "rooms" | "roomListSource"> {
    apiUrl: string
    pageSize: number
    pageParam?: string
}

export interface IRoomsLoadResult {
    totalCount: number
    matchedCount: number
    page?: number
}


interface IApiRoomlistState {
    loadedRooms: IRoomInfo[]
    roomListId?: string
}

export class PaginatedApiRoomList extends RoomList<HomePageRoomCard, IApiRoomlistState> {
    private props: IApiRoomlistProps
    private roomsIterator: PaginatedApiRoomsIterator
    // categoryFilters are filter values that remain static for a given page, i.e. filters set by category
    // page roomlists which can't be toggled by the user. They're tracked separately from the "dynamic"
    // filter values and override specific user preferences when applicable (mostly just region checkboxes)
    private categoryFilters: IRoomListAPIParams
    private filters: IRoomListAPIParams
    private fetchId = 0  // Used for resolving potential race conditions
    private readonly placeholderRooms: Element[]

    constructor(props: IApiRoomlistProps) {
        super({
            roomListSource: RoomListSource.Unknown,
            animate: props.animate,
            showLocation: props.showLocation,
            rooms: () => this.state.loadedRooms,
            // Optional source info properties. `sourceIndex` doesn't apply since this is a top-level roomlist.
            hashtag: props.hashtag ?? (() => getPageHashtag()),
            sourceInfo: props.sourceInfo ?? ((evt) => evt.roomInfo.sourceInfo),
        })

        if (!isRoomRoomlistSpaActive()) {
            this.listeners.add(loadRoomRequest.listen((room) => {
                if (parseQueryString(window.location.search)["join_overlay"] !== undefined && shouldShowJoinOverlay()) {
                    // If join overlay is enabled, ignore loadRoomRequest and allow overlay handler to intercept
                    return
                }

                    window.location.assign(`/${room}/`)

                // Pass syncHistory as false to prevent Safari errors. Due to caching behavior, if we navigate into a room
                // via loadRoomRequest then return to the roomlist via the back button, the loadRoomRequest event will still
                // be in the EventRouter history. If we then construct a new roomlist after pageload (such as when navigating
                // to the /spy-on-cams/ page), this listener will fire IMMEDIATELY when added, taking us back to the room.
            }, false))
        }

        this.categoryFilters = {}
        this.filters = {}
        this.props = props

        const initialPage = getCurrentPage(this.props.pageParam)
        this.roomsIterator = new PaginatedApiRoomsIterator({
            apiUrl: props.apiUrl,
            currentPage: isFilterInPathActive() ? 1 : initialPage,
            pageSize: props.pageSize,
        })
        if (!isFilterInPathActive()) {
            this.setState({
                loadedRooms: [],
                roomListId: undefined,
            })
        } else {
            this.state.loadedRooms = []
            // here we clone the placeholder room cards from the template which is slightly faster than creating
            // these elements from the scratch. This would also allow us to have the placeholder elements only in one
            // place which would be the django template. Alternatively, we could generate these placeholders on the
            // prefetch step and use them here.
            const placeholderList = document.querySelector(".roomlist_container.placeholder ul") as HTMLElement
            this.placeholderRooms = Array.from((placeholderList.cloneNode(true) as HTMLElement).children)
        }
    }

    addPlaceHolderLoadingRooms(isFirstLoad: boolean): void {
        if (isFilterInPathActive()) {
            // if it's not the first load, we add less placeholder cards to avoid massive layout shift
            this.element.append(...isFirstLoad ? this.placeholderRooms : this.placeholderRooms.slice(0, 5))
            return
        }
        let numPlaceholderCards = DEFAULT_NUM_PLACEHOLDER_CARDS
        if (this.rooms.length > 0) {
            numPlaceholderCards = this.rooms.length
        }
        this.removeAllChildren()
        for (let i = 0; i < numPlaceholderCards; i++) {
            this.addChild(createPlaceholderCard())
        }
    }

    /** Base-class method overrides **/

    protected createElement(): HTMLUListElement {
        // We need RoomReload to recognize the roomlist container so it fires the onSuccess handler and
        // correctly hides/shows the searching overlay etc, but this component should handle its own
        // API requests for room reloads, hence adding .endless_page_template with the skip attribute
        return <ul className="list endless_page_template" data-href="skip"></ul>
    }

    protected createRoomCard(roomProps: IRoomCardProps): HomePageRoomCard {
        return new HomePageRoomCard(roomProps)
    }

    protected getOptionalSourceInfo(evt: IRoomClickDetails, props: IRoomListProps): RoomSourceOptionalFields {
        const sourceInfo = super.getOptionalSourceInfo(evt, props)
        if (this.state.roomListId !== undefined) {
            sourceInfo["roomListId"] = this.state.roomListId
        }
        return sourceInfo
    }

    /** API filter helper methods **/

    /**
     * Sets the dynamic API filter values and (if applicable) the current page, updating the rooms iterator accordingly
     * @param newFilters The new dict of filter values to apply. Overwrites the previous dynamic filters entirely, but
     *     static filter values in this.categoryFilters will be unchanged and still take precedence.
     * @param setPage Optional, if passed will also set the pagination to the given page
     */
    public updateFilters(newFilters: IRoomListAPIParams, setPage?: number): void {
        this.filters = newFilters
        const composedFilters = { ...this.filters, ...this.categoryFilters }
        this.roomsIterator.setFilters(composedFilters)

        if (setPage !== undefined) {
            this.setPage(setPage)
        }
    }

    /**
     * Updates the category filter values, but DOES NOT reload the roomlist, as most contexts in which the category
     * filters get updated will also be accompanied by a call to updateFilters() and this avoids redundant fetches.
     * @param newFilters The new dict of category filter values. Overwrites the previous category filters entirely,
     *     but keeps the most-recently-set values in this.filters so the new filters can get applied on top of them.
     */
    public setCategoryFilters(newFilters: IRoomListAPIParams): void {
        this.categoryFilters = newFilters
    }

    /** Room chunk loading methods **/

    /**
     * Fetches a page of rooms from the room iterator and updates the state accordingly, triggering a re-render.
     * Increments this.fetchId for the sake of race-condition avoidance.
     * @param prefetchPromise Optional, if provided will attempt to get results from this promise rather than
     *     making a new API request (will retry with new requests as typical upon failure, however)
     * @param filters Optional, if provided will set the filters for the room iterator before fetching
     */
    public fetchRooms(prefetchPromise?: Promise<string>, filters?: IRoomListAPIParams): Promise<IRoomsLoadResult> {
        this.fetchId += 1
        if (filters !== undefined) {
            this.roomsIterator.setFilters(filters)
        }
        this.showLoading(prefetchPromise !== undefined)
        return new Promise((resolve, reject) => {
            this.roomsIterator.fetchPage(this.fetchId, undefined, prefetchPromise)
                .then(({ loadedRooms, totalCount, matchedCount, roomListId, fetchId }) => {
                    if (fetchId !== this.fetchId) {
                        return  // A more recent room fetch request was fired before this one resolved, discard results
                    }
                    const currentPage = isFilterInPathActive() ? undefined : this.roomsIterator.getPage()
                    this.setState({
                        ...this.state,
                        loadedRooms,
                        roomListId,
                    })
                    resolve({
                        totalCount,
                        matchedCount,
                        page: currentPage,
                    })
                }).catch((err) => {
                    reject(err)
                }).finally(() => {
                    this.hideLoading()
                })
        })
    }

    /** Event handler methods **/

    /**
     * Update the roomsIterator property which serves as the source of truth of currentPage for this class
     * @param pageNum The page being changed to
     */
    public setPage = (pageNum: number): void => {
        this.roomsIterator.setPage(pageNum)
    }

    /** Direct DOM manipulation helper methods **/

    public showLoading(isFirstLoad: boolean): void {
        if (this.state.loadedRooms.length === 0) {
            this.addPlaceHolderLoadingRooms(isFirstLoad)
        } else {
            // Only show the loading overlay if we aren't showing placeholder cards, to create a visual
            // difference in the previously-valid room cards that are still displayed in the interim.
            this.element.classList.add("loading")
        }
    }

    private hideLoading() {
        // Ensure .loading overlay is not present -- no need to remove any placeholder cards
        // since that's handled automatically by resetRooms()
        this.element.classList.remove("loading")
    }

    protected resetRooms(): void {
        if (isFilterInPathActive()) {
            this.removeAllDOMChildren()
        }
        super.resetRooms()
    }
}

class HomePageRoomCard extends RoomCard {
    /**
     * Temporary component to fix interactions between components that extend from FollowStar and components that extend from the DropDownComponent.
     * TODO: Look into a permanent fix for component interaction. Due to usage of stopPropagation within FollowStar, documentClickEvent is never fired
     * to hide DropDownComponents.
     * https://multimediallc.leankit.com/card/30502080131375
     **/
    protected createFollowStar(props: IRoomCardProps): RoomFollowStar {
        return <RoomFollowStar slug={props.roomInfo.room} isFollowing={props.roomInfo.isFollowing}
            classRef={(c: RoomFollowStar) => this.followStar = c} allowPropagation={true}
        />
    }

    protected createElement(props: IRoomCardProps): HTMLLIElement {
        const el = super.createElement(props)
        const extendedInfo = props.roomInfo.extendedInfo
        if (extendedInfo !== undefined) {
            // If extended room info is defined (i.e. if we're on internal.chaturbate and URL contains ?show_rankings=1)
            // then add an additional child div that exposes the extended ranking information
            let penaltyText
            if (Boolean(extendedInfo.manualPenalty)) {
                penaltyText = <Fragment><s>{extendedInfo.defaultPenalty}</s> <b>{extendedInfo.manualPenalty}</b></Fragment>
            } else {
                penaltyText = <b>{extendedInfo.defaultPenalty}</b>
            }
            const coloredRegs = extendedInfo.coloredRegs ?? 0
            // We want the displayed regs count to NOT include colored regs since they're shown separately
            const numRegs = (extendedInfo.numRegs ?? 0) - coloredRegs
            el.appendChild(<div className="extended-room-info">
                <span><b>Default:</b> #{extendedInfo.scoreRank ?? "X"}</span><br />
                <span><b>Base:</b> #{extendedInfo.baseRank ?? "X"}</span><br />
                <span><b>Satisfaction:</b> {extendedInfo.satPercent ?? "X"}%</span><br />
                <span><b>Penalty:</b> {penaltyText}</span><br />
                <span><b>Grey:</b> {numRegs} | Color: {coloredRegs}</span><br />
                <span><b>Tip rate:</b> {(extendedInfo.tipRate ?? 0).toFixed(2)}/hr</span>
            </div>)
        }
        return el
    }
}
