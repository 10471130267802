import {
    isHomepageTab,
    isOfflineFollowed,
    isRoomRoomlistSpaActive,
    shouldShowHomepageFilters,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { usernameTitleCase } from "@multimediallc/web-utils"
import { isiPhone, isiPod } from "@multimediallc/web-utils/modernizr"
import { normalizeResource } from "../../../common/api"
import { roomLoaded } from "../../../common/context"
import { Component } from "../../../common/defui/component"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { applyStyles, getScrollbarWidth, hoverEvent } from "../../../common/DOMutils"
import { EventRouter, ListenerGroup } from "../../../common/events"
import { isFilterInPathActive } from "../../../common/featureFlagUtil"
import {
    Gender,
    GenderNameToSymbolMap,
    GendersSymbolToNameMap,
    getCurrentGender,
    getVerboseGenderPath,
    parseSimpleGender,
} from "../../../common/genders"
import { convertAllChildLinksToJoinOverlayAnchors } from "../../../common/theatermodelib/joinOverlay"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { PageLocation, spaPageContext } from "../../interfaces/context"
import { CollapsibleComponent, ExpandableDropDownMenu } from "../../ui/expandableDropDownMenu"
import { SearchBlur, SearchInput } from "../../ui/searchBar/searchInput"
import { isCategoryPage } from "./filters/filtersUtil"
import {
    genderFilterUpdateFromNav,
    isRoomRoomlistSpaEligiblePage,
} from "./spaHelpers"
import type { IRoomContext } from "../../../common/context"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch"

export interface ISubNavHeaderTabsProps {
    firstTabTitle?: string  // Override the first tab title to something other than "Featured"
    enableGenderedLinkUpdates?: boolean
    skipJoinOverlaySetup?: boolean
    onTabClick?: (evt: MouseEvent) => void
}

export const GenderDropdownToggle = new EventRouter<boolean>("GenderDropdownToggle")

export class SubNavHeaderTabs extends HTMLComponent<HTMLUListElement, ISubNavHeaderTabsProps> {
    private props: ISubNavHeaderTabsProps
    private firstTab: SubHeaderTab
    private tabsDropdown: ExpandableDropDownMenu
    private listeners: ListenerGroup

    // instance only to be used temporarily used for ff-RmRmlstSpa before shared entrypoint is completed
    // will be cleaned up in card: https://multimediallc.leankit.com/card/30502080746332
    private static instance?: SubNavHeaderTabs

    protected createElement(props: ISubNavHeaderTabsProps): HTMLUListElement {
        this.props = props
        this.listeners = new ListenerGroup()

        return <ul className="sub-nav genderTabs" style={{
            display: "block",
            top: "1px",
            marginTop: "2px",
        }} />
    }

    renderTabs(): void {
        // First clean up any listeners that may have been added via makeComponentResponsive()
        this.listeners.removeAll()
        this.tabsDropdown?.dispose()

        const currentGender = getCurrentGender()
        const linkUpdates = this.props.enableGenderedLinkUpdates ?? false
        this.removeAllDOMChildren()
        this.firstTab = new SubHeaderTab({
            text: this.getFirstTabText(),
            isActive: currentGender === Gender.All,
            gender: Gender.All,
            enableGenderedLinkUpdates: linkUpdates,
            onTabClick: this.props.onTabClick,
        })
        this.addChild(this.firstTab)
        if (!isOfflineFollowed()) {
            [Gender.Female, Gender.Male, Gender.Couple, Gender.Trans].forEach((gender) => {
                this.element.appendChild(<SubHeaderTab
                    isActive={currentGender === gender}
                    gender={gender}
                    enableGenderedLinkUpdates={linkUpdates}
                    onTabClick={this.props.onTabClick}
                />)
            })
        }
        // Since we reconstruct the tab components every render, need to re-call these functions every time
        this.makeComponentResponsive()
        if (!Boolean(this.props.skipJoinOverlaySetup) && this.element.isConnected) {
            convertAllChildLinksToJoinOverlayAnchors(this.element)
        }
    }

    protected initUI(props: ISubNavHeaderTabsProps): void {
        super.initUI(props)
        this.element.style.overflow = "hidden"
        this.element.style.position = "relative"
        this.element.style.display = "block"
        this.element.style.top = "1px"
        this.element.style.marginTop = "2px"
        this.renderTabs()

        window.setTimeout(() => {
            if (!isiPhone() && !isiPod()) {
                // on iPhone body's minWidth is set as width making the screen 500px
                document.body.style.minWidth = `${500 - getScrollbarWidth()}px`
            }
        })
        if (isFilterInPathActive()) {
            UrlState.current.listen(["genders", "showType"], () => {
                this.renderTabs()
            }, this.element)
        }
        if (isRoomRoomlistSpaActive()) {
            roomLoaded.listen((context: IRoomContext) => {
                this.renderTabs()
            })
        }
    }

    protected initData(props: ISubNavHeaderTabsProps): void {
        super.initData(props)
        SearchInput.onSubmit.addListener(() => {
            this.renderTabs()
        }, this.element)
    }

    private makeComponentResponsive(): void {
        if (this.element === null || !(this.element instanceof HTMLElement)) {
            return
        }
        const navComponent = new Component(this.element)
        this.makeGenderTabsResponsive(navComponent)
        this.makeExpandableDropDownResponsive(navComponent)
    }

    private makeGenderTabsResponsive(navComponent: Component): void {
        Array.from(this.element.children).forEach(childEl => {
            if (childEl instanceof HTMLElement) {
                childEl.style.display = "inline-block"
                childEl.style.position = "relative"
                childEl.style.font = getComputedStyle(childEl).font
                const collapsibleComponent = new CollapsibleComponent<HTMLElement>(childEl)
                this.listeners.add(collapsibleComponent.onCollapseEvent.listen(collapsed => {
                    collapsibleComponent.element.style.margin = collapsed ? "5px 0" : ""
                    collapsibleComponent.element.style.display = collapsed ? "block" : "inline-block"
                }))
                if (childEl.firstElementChild instanceof HTMLAnchorElement) {
                    childEl.firstElementChild.style.cursor = getComputedStyle(childEl.firstElementChild).cursor
                    childEl.firstElementChild.style.textDecoration = "none"
                    hoverEvent(childEl.firstElementChild, { ignoreTouch: true }).listen((isHover) => {
                        if (isHover) {
                            childEl.firstElementChild?.classList.add("hover")
                        } else {
                            childEl.firstElementChild?.classList.remove("hover")
                        }
                    }).addTo(this.listeners)
                }
                navComponent.attachChild(collapsibleComponent)
            }
        })
    }

    private makeExpandableDropDownResponsive(navComponent: Component): void {
        // UI changes that occur in response to SearchBlur can move elements such that
        // the click event's intended target can be gone before the event can be registered.
        // So, forward the toggleEvent info from the private child component to a public EventRouter visible to
        // RoomlistSearchInput, so that it responds immediately to the gender tab dropdown being toggled.
        // This way, the onInputBlur UI changes can trigger in response to the click, rather than either
        // immediately before it or on a timeout
        this.tabsDropdown = new ExpandableDropDownMenu()
        this.tabsDropdown.element.classList.add("gender-tab")
        navComponent.addChild(this.tabsDropdown)

        this.tabsDropdown.dropDown.toggleEvent.listen((evt) => {
            GenderDropdownToggle.fire(evt.isShowing)
        })
        this.listeners.add(SearchBlur.listen((evt: FocusEvent) => {
            // if search input is about to close, wait for it to collapse before updating
            this.tabsDropdown.collapseIfNeeded()
            window.setTimeout(() => {
                this.tabsDropdown.collapseIfNeeded()
            }, 250)
        }))
        applyStyles(this.tabsDropdown, {
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px 4px 0 0",
            height: "27px",
            lineHeight: "27px",
            marginRight: "2px",
            width: "41px",
        })
        applyStyles(this.tabsDropdown.dropDown, {
            padding: "8px 20px 8px 0",
            width: "108px",
        })
    }

    public get maxAvailableNonTabSpace(): number {
        const minimumTabsWidth = this.firstTab.element.offsetWidth + this.tabsDropdown.element.offsetWidth
        return this.element.offsetWidth - minimumTabsWidth
    }

    private getFirstTabText(): string {
        let firstTabText
        const defaultFirstTabText = shouldShowHomepageFilters() || isHomepageTab() || isCategoryPage() ? i18n.featuredCAPS : i18n.allGendersCAPS
        if (isRoomRoomlistSpaActive() && UrlState.current.state.room !== undefined) {
            firstTabText = `${usernameTitleCase(UrlState.current.state.room)}'s Cam`
        } else {
            firstTabText = this.props.firstTabTitle ?? defaultFirstTabText
        }
        return firstTabText
    }

    static getInstance(props: ISubNavHeaderTabsProps = {}): SubNavHeaderTabs {
        // instance only to be used temporarily used for ff-RmRmlstSpa before shared entrypoint is completed
        // will be cleaned up in card: https://multimediallc.leankit.com/card/30502080746332
        if (!SubNavHeaderTabs.instance) {
            SubNavHeaderTabs.instance = new SubNavHeaderTabs(props)
        }
        return SubNavHeaderTabs.instance
    }

    dispose(): void {
        this.listeners.removeAll()
    }
}

interface ISubHeaderTabState {
    isActive?: boolean
    text?: string  // Can provide an override on text if desired
}

interface ISubHeaderTabProps extends ISubHeaderTabState {
    isActive: boolean
    enableGenderedLinkUpdates: boolean
    gender: Gender
    onTabClick?: (event: MouseEvent) => void
}

export class SubHeaderTab extends HTMLComponent<HTMLLIElement, ISubHeaderTabProps, ISubHeaderTabState> {
    private anchorElement: HTMLAnchorElement

    public static GenderSymbolToPluralCapsCategoryMap = new Map([
        [Gender.All, ""],
        [Gender.Male, i18n.menCAPS],
        [Gender.Female, i18n.womenCAPS],
        [Gender.Couple, i18n.couplesCAPS],
        [Gender.Trans, i18n.transCAPS],
        [Gender.OldTrans, i18n.transCAPS],
    ])

    protected createElement(props: ISubHeaderTabProps): HTMLLIElement {
        const testIdName = props.text?.toLowerCase() ?? SubHeaderTab.GenderSymbolToPluralCapsCategoryMap.get(props.gender) ?? ""
        return <li className="gender-tab"><a ref={(el: HTMLAnchorElement) => { this.anchorElement = el }}
            className={`gender-tab tabElement tabElementLink${props.isActive ? " active" : ""}`}
            data-paction="TopTab"
            data-testid={(`top-section-tab-${testIdName.toLowerCase()}`).replace(/ /g, "-")}
            href={getFullGenderedPathWithKeywords(props.gender)}
            onClick={(event: MouseEvent) => {
                if (this.state.isActive ?? false) {
                    event.preventDefault()
                    return
                }
                if (event.ctrlKey || event.metaKey || event.shiftKey) {
                    return
                }
                if (props.enableGenderedLinkUpdates) {
                    // Prevent navigation, no point updating links if we load a new page
                    event.preventDefault()
                    if (isFilterInPathActive()) {
                        UrlState.current.setPartialState({ genders: [props.gender], page: 1, pageb: 1 }, false)
                    } else {
                        genderFilterUpdateFromNav.fire(props.gender)
                    }
                }
                if (props.onTabClick !== undefined) {
                    props.onTabClick(event)
                }
            }}
            bind={{ text: () => this.state.text }}>
            {props.text ?? SubHeaderTab.GenderSymbolToPluralCapsCategoryMap.get(props.gender)}
        </a></li>
    }

    initData(props: ISubHeaderTabProps): void {
        this.setState({
            text: this.anchorElement.innerText,
            isActive: props.isActive,
        })
    }

    updateState(): void {
        super.updateState()
        if (this.state.isActive !== undefined) {
            if (this.state.isActive) {
                this.anchorElement.classList.add("active")
            } else {
                this.anchorElement.classList.remove("active")
            }
        }
    }
}

function getGenderedBaseUrl(gender: Gender): string {
    if (gender === Gender.All) {
        return getNonGenderedPathname()
    } else if (isHomepageTab() || (isRoomRoomlistSpaActive() && spaPageContext.getState().pageLocation === PageLocation.RoomPage)) {
        return `/${getVerboseGenderPath(gender)}/`
    } else {
        return `${getNonGenderedPathname()}${GendersSymbolToNameMap.get(gender)}/`
    }
}

function getFullGenderedPathWithKeywords(gender: string): string {
    const parsedGender = parseSimpleGender(gender)
    let targetUrl
    if ( isRoomRoomlistSpaEligiblePage() ) {
        const partialState: Partial<IURLState> = {
            genders: [parsedGender],
            room: undefined,
        }
        targetUrl = UrlState.current.getURLForPartialState(partialState, false)
    } else {
        const queryStr = getKeywords()
        targetUrl = `${getGenderedBaseUrl(parsedGender)}${queryStr ? `?${queryStr}` : ""}`
    }
    return normalizeResource(targetUrl)
}

export function getKeywords(): string {
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.delete("g")
    queryParams.delete("page")  // Don't want to retain page number between gender tab navigations.
    const searchOrParam = queryParams.get("keywords")
    if (searchOrParam !== null && searchOrParam !== "") {
        queryParams.set("keywords", searchOrParam)
    } else {
        queryParams.delete("keywords")
    }
    return queryParams.toString()
}

/**
 * Takes a URL path, which _may or may not_ end with a gender-filtering slug such as
 * /tag/cute/f/ or /new-cams/couple/ and returns a base path without the gender included,
 * e.g. just /tag/cute/ or /new-cams/
 * NOTE: Also checks for main homepage tabs such as /trans-cams/ and returns /, but
 * otherwise only handles paths where the gender is expressed in the final URL slug.
 * The choice to include OR omit a leading slash will be preserved, but trailing slash
 * is always enforced. (e.g. "tag/cute/f/" => "tag/cute/", "/new-cams" => "/new-cams/")
 * @param path optional pathname string, if not provided uses the current page path.
 * @returns A gender-neutral path
 */
function getNonGenderedPathname(): string {
    let pathName = window.location.pathname
    if (!pathName.endsWith("/")) {
        pathName += "/"
    }
    if (isHomepageTab(pathName)) {
        return "/"
    }
    const pathSegments = pathName.split("/")
    const lastSegment = pathSegments[pathSegments.length - 2] ?? ""

    const isNonGenderedTagPage = pathSegments.length === 4 && pathSegments[1] === "tag"
    if (!isNonGenderedTagPage && ((Boolean(lastSegment) && GenderNameToSymbolMap.has(lastSegment)) || parseSimpleGender(lastSegment) !== Gender.All)) {
        // Remove gender slug but not the final "segment" (empty string), we want returned path to end with /
        pathSegments.splice(-2, 1)
    }
    return pathSegments.join("/")
}
