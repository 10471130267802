import { ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { isFilterInPathActive } from "../../../common/featureFlagUtil";
import { i18n } from "../../../common/translation";
import { dom } from "../../../common/tsxrender/dom"


interface INumOfRooms {
    total: number
    matched: number
    isLastPage: boolean
    hasFilter: boolean
}

interface INumOfRoomsProps {
    isSecondary: boolean
}

export class NumberOfRoomsMessage extends HTMLComponent<HTMLDivElement, INumOfRoomsProps, INumOfRooms> {
    initData(props: INumOfRoomsProps): void {
        super.initData(props);
    }

    protected createElement(props: INumOfRoomsProps): HTMLDivElement {
        return <div className="num-of-rooms-msg" data-testid="num-of-rooms-msg" bind={{
            display: () => this.state.isLastPage && this.state.hasFilter ? "flex" : "none",
            text: () => isFilterInPathActive() ? this.getMessage(props) :
                (this.state.matched > 0 ?
                    i18n.numberOfRoomsMatchesYourFilters(this.state.total, this.state.matched) :
                    i18n.noRoomsMatchesYourFilters(this.state.total)),
        }}>
        </div>
    }

    private getMessage(props: INumOfRoomsProps) {
        if (props.isSecondary || UrlState.current.state.showType === ShowType.HIDDEN) {
            return this.state.matched > 0 ?
                i18n.numberOfHiddenRoomsMatchesYourFilters(this.state.total, this.state.matched) :
                i18n.noHiddenRoomsMatchesYourFilters(this.state.total)
        } else if (UrlState.current.state.showType === ShowType.PRIVATE) {
            return this.state.matched > 0 ?
                i18n.numberOfPrivateRoomsMatchesYourFilters(this.state.total, this.state.matched) :
                i18n.noPrivateRoomsMatchesYourFilters(this.state.total)
        } else if (UrlState.current.state.showType === ShowType.FOLLOW) {
            return this.state.matched > 0 ?
                i18n.numberOfFollowedRoomsMatchesYourFilters(this.state.total, this.state.matched) :
                i18n.noFollowedRoomsMatchesYourFilters(this.state.total)
        } else {
            return this.state.matched > 0 ?
                i18n.numberOfRoomsMatchesYourFilters(this.state.total, this.state.matched) :
                i18n.noRoomsMatchesYourFilters(this.state.total)
        }
    }
}
