import { setCookieWithExpiration } from "@multimediallc/web-utils/storage"
import { normalizeResource } from "../../common/api"
import { Component } from "../../common/defui/component"
import { isLanguageSubdomainActive } from "../../common/featureFlagUtil"
import { addPageAction } from "../../common/newrelic"
import { i18n } from "../../common/translation"
import { openFeedbackForm } from "./userMenus/ui/userFeedbackFormEvents"

export class MobileSiteNotice extends Component {
    protected innerDiv: HTMLElement
    protected mobileLink: HTMLAnchorElement

    constructor() {
        super()

        this.element.style.position = "relative"
        this.element.style.display = "none"
        this.innerDiv = document.createElement("div")
        this.innerDiv.className = "top_alert"
        this.element.appendChild(this.innerDiv)

        const closeButton = document.createElement("span")
        closeButton.className = "top_alert_close"
        closeButton.innerText = "×"
        closeButton.style.fontSize = "2em"
        closeButton.dataset.testid = "mobile-site-notice-close"
        this.innerDiv.appendChild(closeButton)

        closeButton.onclick = () => {
            setCookieWithExpiration("mobile_redirect_hide", "true", { hours: 18 }, "/", isLanguageSubdomainActive())
            this.element.style.display = "none"
        }

        const topSection = document.createElement("div")
        const topSpan = document.createElement("span")
        topSpan.textContent = i18n.improvedMobileSite
        topSection.appendChild(topSpan)

        const middleSection = document.createElement("div")
        const middleList = document.createElement("ul")
        middleList.style.listStyleType = "disc"

        const firstBullet = document.createElement("li")
        firstBullet.textContent = i18n.myCollectionText
        const secondBullet = document.createElement("li")
        secondBullet.textContent = i18n.satisfactionVoting
        const thirdBullet = document.createElement("li")
        thirdBullet.textContent = i18n.reportAbuse

        middleList.appendChild(firstBullet)
        middleList.appendChild(secondBullet)
        middleList.appendChild(thirdBullet)
        middleSection.appendChild(middleList)

        const bottomSection = document.createElement("div")
        const bottomSpan = document.createElement("span")
        bottomSpan.innerHTML = i18n.visitMobileSiteOrFeedback() // eslint-disable-line @multimediallc/no-inner-html
        this.mobileLink = bottomSpan.querySelectorAll("a")[0]
        this.mobileLink.dataset.testid = "mobile-site-notice-link"
        const surveyLink = bottomSpan.querySelectorAll("a")[1]
        bottomSection.appendChild(bottomSpan)

        this.mobileLink.onclick = () => {
            addPageAction("MobileAlertLinkClicked")
        }
        surveyLink.onclick = () => {
            addPageAction("MobileAlertSurveyClicked")
            openFeedbackForm.fire({ source: "desktop_on_mobile" })
        }

        this.innerDiv.appendChild(topSection)
        this.innerDiv.appendChild(middleSection)
        this.innerDiv.appendChild(bottomSection)
    }

    show(room?: string): void {
        let url: string
        if (room !== undefined) {
            // If a room is provided, construct the URL with the room parameter
            url = `https://m.chaturbate.com/mobile/?b=${room}&source=desktop`
        } else {
            // If no room is provided, route directly to the base mobile URL
            url = `https://m.chaturbate.com/`
        }

        // Normalize the URL and set it to the href of mobileLink
        this.mobileLink.href = normalizeResource(url)
        this.element.style.display = "block"
    }
}
