import { ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { isFilterInPathActive } from "../../../common/featureFlagUtil";
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"

export interface FollowedOnlineOfflineTabProps {
    online?: boolean
    onFilterClick?: (event: MouseEvent) => void
}

export class FollowedOnlineOfflineTab extends HTMLComponent<HTMLElement, FollowedOnlineOfflineTabProps> {
    // This component manages the bolded & non-bolded text used to toggle between displaying
    // online and offline follwed cams

    protected createElement(props: FollowedOnlineOfflineTabProps): HTMLElement {
        if (isFilterInPathActive()) {
            return <div
                bind={{
                    display: () =>
                        UrlState.current.state.showType === ShowType.FOLLOW || UrlState.current.state.showType === ShowType.FOLLOW_OFFLINE ? "block" : "none",
                }}
                className="followed_online_offline" data-testid="followed-online-offline">
                <FollowedOnlineOfflineFilter onlineFilter={true}/>
                <FollowedOnlineOfflineFilter onlineFilter={false}/>
            </div>
        }
        return <div className="followed_online_offline" data-testid="followed-online-offline">
            <FollowedOnlineOfflineFilter onlineFilter={true} active={props.online ?? true} onFilterClick={props.onFilterClick}></FollowedOnlineOfflineFilter>
            <FollowedOnlineOfflineFilter onlineFilter={false} active={!(props.online ?? false)} onFilterClick={props.onFilterClick}></FollowedOnlineOfflineFilter>
        </div>
    }

    initUI(props?: FollowedOnlineOfflineTabProps): void {
        super.initUI(props)
        UrlState.current.listen(["showType"], () => {
            this.updateState()
        }, this.element)
        this.updateState()
    }
}

interface IFollowedOnlineOfflineFilter {
    active?: boolean
    onlineFilter: boolean
    onFilterClick?: (event: MouseEvent) => void
}

class FollowedOnlineOfflineFilter extends HTMLComponent<HTMLDivElement, IFollowedOnlineOfflineFilter> {
    protected createElement(props: IFollowedOnlineOfflineFilter): HTMLDivElement {
        return <div className={`title${(!isFilterInPathActive() && (props.active ?? false)) ? " bold" : ""}`}
                    bind={isFilterInPathActive() ? {
                        class: () => `title${this.isActive(props) ? " bold" : ""}` } : {}}
                    data-testid={props.onlineFilter ? "online-filter-title" : "offline-filter-title"}
                    onClick={(e) => {
                        if (isFilterInPathActive()) {
                            UrlState.current.setPartialState({ showType: props.onlineFilter ? ShowType.FOLLOW : ShowType.FOLLOW_OFFLINE })
                            e.preventDefault()
                        }
                    }}
        >
            <p>
                <a
                    href={this.getHref(props.onlineFilter)}
                    onClick={props.onFilterClick}
                    data-testid={props.onlineFilter ? "online-filter-link" : "offline-filter-link"}
                >{props.onlineFilter ? i18n.onlineRooms : i18n.offlineRooms}</a>
            </p>
        </div>
    }

    private getHref(online: boolean): string {
        const showType = online ? ShowType.FOLLOW : ShowType.FOLLOW_OFFLINE
        return UrlState.current.getURLForPartialState({ showType: showType })
    }

    private isActive(props: IFollowedOnlineOfflineFilter): boolean {
        return props.onlineFilter ? UrlState.current.state.showType === ShowType.FOLLOW : UrlState.current.state.showType === ShowType.FOLLOW_OFFLINE
    }
}
