import {
    HOMEPAGE_FILTERS_KEYS,
    PrivatePrices,
    Region,
    RoomSize,
} from "@multimediallc/cb-roomlist-prefetch"
import { i18n } from "../../../../common/translation"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch"

export const FILTER_PANEL_URLSTATE_KEYS: (keyof IURLState)[] = HOMEPAGE_FILTERS_KEYS

export interface FilterOptionProps {
    name: string,
    testid?: string,
    labelText?: string,  // Optional value to display on the FilterLabel instead of the name
    queryParamValue: string,
    getHref: () => string,
    handleLeftClick: () => void,
    optionIsActive: () => boolean,
}

// Convenient shorthand for the FilterOptionProps values that can actually differ within a single section
export type SectionOptionsList = Pick<FilterOptionProps, "name" | "labelText" | "queryParamValue">[]

export const REGION_MAPPING = new Map<Region, string>([
    [Region.NorthAmerica, i18n.northAmericaTitle],
    [Region.SouthAmerica, i18n.southAmericaTitle],
    [Region.Asia, i18n.asiaTitle],
    [Region.EuropeRussia, i18n.europeRussiaTitle],
    [Region.Other, i18n.otherTitle],
])
export const PRIVATE_PRICES_MAPPING = new Map<PrivatePrices, string>([
    [PrivatePrices.Value6, i18n.hmpgFilterOpt6TksAbbrev],
    [PrivatePrices.Range12to18, i18n.hmpgFilterOpt12To18TksAbbrev],
    [PrivatePrices.Range30to42, i18n.hmpgFilterOpt30TksAbbrev],
    [PrivatePrices.Range60to72, i18n.hmpgFilterOpt60TksAbbrev],
    [PrivatePrices.Range90Plus, i18n.hmpgFilterOpt90TksAbbrev],
])

export const ROOM_SIZE_MAPPING = new Map<RoomSize, string>([
    [RoomSize.SMALL, i18n.intimateRoomSizeCategory],
    [RoomSize.MEDIUM, i18n.midSizeRoomSizeCategory],
    [RoomSize.LARGE, i18n.hiTrafficRoomSizeCategory],
])

export const REGION_OPTIONS: SectionOptionsList = [
    { name: i18n.northAmericaTitle, queryParamValue: Region.NorthAmerica },
    { name: i18n.southAmericaTitle, queryParamValue: Region.SouthAmerica },
    { name: i18n.asiaTitle, queryParamValue: Region.Asia },
    { name: i18n.europeRussiaTitle, queryParamValue: Region.EuropeRussia },
    { name: i18n.otherTitle, queryParamValue: Region.Other },
]
export const PRIVATE_PRICE_OPTIONS: SectionOptionsList = [
    { name: i18n.hmpgFilterOpt6TksAbbrev, queryParamValue: PrivatePrices.Value6 },
    { name: i18n.hmpgFilterOpt12To18TksAbbrev, queryParamValue: PrivatePrices.Range12to18 },
    { name: i18n.hmpgFilterOpt30TksAbbrev, queryParamValue: PrivatePrices.Range30to42 },
    { name: i18n.hmpgFilterOpt60TksAbbrev, queryParamValue: PrivatePrices.Range60to72 },
    { name: i18n.hmpgFilterOpt90TksAbbrev, queryParamValue: PrivatePrices.Range90Plus },
]
export const ROOM_SIZE_OPTIONS: SectionOptionsList = [
    {
        name: i18n.intimateRoomSizeCategory,
        labelText: i18n.intimateRoomsLabel,
        queryParamValue: RoomSize.SMALL,
    },
    {
        name: i18n.midSizeRoomSizeCategory,
        labelText: i18n.midSizeRoomsLabel,
        queryParamValue: RoomSize.MEDIUM,
    },
    {
        name: i18n.hiTrafficRoomSizeCategory,
        labelText: i18n.hiTrafficRoomsLabel,
        queryParamValue: RoomSize.LARGE,
    },
]
