import { getPageHashtag, HOMEPAGE_KEYS_NO_PAGE, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { Gender } from "@multimediallc/gender-utils"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "../../../../../common/api"
import { HTMLComponent } from "../../../../../common/defui/htmlComponent"
import { isFilterInPathActive, isTagFilterPaginationActive } from "../../../../../common/featureFlagUtil"
import { getCurrentGender, getVerboseGenderPath } from "../../../../../common/genders"
import { addPageAction } from "../../../../../common/newrelic"
import { i18n } from "../../../../../common/translation"
import { dom } from "../../../../../common/tsxrender/dom"
import { resizeDebounceEvent } from "../../../../ui/responsiveUtil"
import { hashtagUrl } from "../../../../util/hashtagsUtils"
import { ReactComponentRegistry } from "../../../ReactRegistry"
import { FilterOption } from "../filterOption"
import { getRoomlistCategoryFilters, getRoomlistDynamicFilters } from "../filtersUtil";
import { getGenderForTagsApi } from "../homepageFiltersUtil"
import { TagSearch } from "../tagSearch"
import type { ReactComponent } from "../../../ReactRegistry"
import type { IRoomListAPIParams } from "@multimediallc/cb-roomlist-prefetch";


interface TagSectionProps {
    onFilterOptionClick: () => void,
}

interface TagSectionState {
    isLoading: boolean
    tagPageNum: number
    maxPageNum: number
}

const TOP_TAGS_FETCH_COUNT = isTagFilterPaginationActive() ? 1000 : 100
const MAX_ROWS_TAG_OPTIONS = 13
const ROOMLIST_ALL_TAGS_API_URL = "api/ts/roomlist/all-tags/"

export class TagSection extends HTMLComponent<HTMLDivElement, TagSectionProps, TagSectionState> {
    private currentPageTagsList: string[]
    private optionsContainer: HTMLDivElement
    private tagSearch: TagSearch
    private topTagsList: string[]
    private props: TagSectionProps
    private pageMinIndices: number[]
    private tagPagination?: ReactComponent
    private onlineTagsMatchingFilters: string[]  // The set of hashtags matching current roomlist filters.
    // It is set by loadOnlineTopTags(), and is not necessarily equal to this.onlineTopTagsFiltered due to score sorting
    private onlineTopTagsFiltered: string[]  // The score-sorted top-tags results that have rooms online matching the current roomlist filters
    private tagPaginationRoot: HTMLDivElement

    protected createElement(): HTMLDivElement {
        this.state.isLoading = true  // sets a placeholder min-height to tagSectionOptions while loading
        this.pageMinIndices = [0]

        const isTagPaginationActive = isTagFilterPaginationActive()
        if (isTagPaginationActive) {
            const TagPagination = ReactComponentRegistry.get("TagPagination")
            this.tagPaginationRoot = <div className="tagPaginationRoot"></div>
            this.tagPagination = new TagPagination({
                isDisabled: this.state.isLoading,
                currentPage: 1,
                maxPage: 1,
                onNextPageClick: (e: MouseEvent | KeyboardEvent): void => {
                    if (e instanceof MouseEvent && (e.ctrlKey || e.metaKey || e.shiftKey) || this.state.isLoading) {
                        return
                    }
                    this.updatePageNum(this.state.tagPageNum !== this.state.maxPageNum ? this.state.tagPageNum + 1 : 1)
                    this.renderTagsPage()
                },
                onPrevPageClick: (e: MouseEvent | KeyboardEvent): void => {
                    if (e instanceof MouseEvent && (e.ctrlKey || e.metaKey || e.shiftKey) || this.state.isLoading) {
                        return
                    }
                    this.updatePageNum(this.state.tagPageNum !== 1 ? this.state.tagPageNum - 1 : this.state.maxPageNum)
                    this.renderTagsPage()
                },
            }, this.tagPaginationRoot)
        }
        this.tagSearch = new TagSearch({
            applyTagFilter: (tag: string) => {
                // Noop when tag is already selected
                if (getPageHashtag() === tag) {
                    return
                }
                if (isFilterInPathActive()) {
                    UrlState.current.setPartialState({ tags: [tag], page: 1, pageb: 1 } )
                } else {
                    this.toggleTagInURL(tag)  // trigger the urlstate listener to update the UI
                }
                this.props.onFilterOptionClick()
            },
        })
        return <div
            bind={{
                className: () => `${this.state.maxPageNum > 1 ? "multiPage " : ""}${isTagPaginationActive ? "paginated" : ""} tagSection filterSection`,
            }}
            data-testid="filter-tag-section">
            <div className="filterSectionHeader" data-testid="filter-tag-header">{i18n.tagsCAPS}</div>
            {this.tagSearch.element}
            <div
                ref={(el: HTMLDivElement) => { this.optionsContainer = el }}
                bind={{
                    className: () => `filterSectionOptions tagSectionOptions ${this.state.isLoading && this.state.maxPageNum > 1 ? "loading" : ""}`,
                }}
            ></div>
            {this.tagPaginationRoot}
        </div>
    }

    protected initUI(): void {
        resizeDebounceEvent.addListener(() => {
            this.renderTagsPage(true)
        }, this)
    }

    protected initData(props: TagSectionProps): void {
        super.initData(props)

        UrlState.current.listen(HOMEPAGE_KEYS_NO_PAGE, () => {
            void this.updateTopTagsLists(false).then(() => this.renderTagsPage())
        }, this.element)

        // fetch top tags AND all roomlist-filtered-tags when gender changes
        UrlState.current.listen([
            "genders",
        ], () => {
            void this.updateTopTagsLists(true).then(() => this.renderTagsPage())
        }, this.element)

        // only change tag option selection state and order of current tags page when tag selection changes
        UrlState.current.listen(["tags"], () => { this.renderTagsPage() }, this.element)
        this.props = props
        this.topTagsList = []
        this.onlineTopTagsFiltered = []
        void this.updateTopTagsLists(true).then(() => this.renderTagsPage())
    }

    updateState(): void {
        super.updateState()
        if (this.tagPagination !== undefined) {
            // add or remove the pagination component if the current filters selection yield 2 or more pages
            // of tag options
            if (this.state.maxPageNum <= 1 && this.element.contains(this.tagPaginationRoot)) {
                this.element.removeChild(this.tagPaginationRoot)
            } else if (this.state.maxPageNum > 1) {
                this.element.appendChild(this.tagPaginationRoot)
            }
            this.tagPagination.update({ currentPage: this.state.tagPageNum, maxPage: this.state.maxPageNum, isDisabled: this.state.isLoading })
        }
    }

    private updatePageNum(pageNum: number): void {
        this.setState({ ...this.state, tagPageNum: pageNum })
    }

    private updateMaxPageNum(responsivePaging: boolean): void {
        this.setPagingData(responsivePaging)
        this.setState({ ...this.state, maxPageNum: this.state.maxPageNum })
    }

    private async updateTopTagsLists(fetchTopTags: boolean): Promise<void> {
        // Loads all unique tags matching current filter set, and optionally loads the
        // top tags list depending on boolean argument fetchTopTags.  The top tags list
        // only varies with gender, whereas the current filter set encompasses gender and
        // other filters.
        this.setState({ ...this.state, tagPageNum: 1, isLoading: true })

        if (isTagFilterPaginationActive()) {
            const loadOnlineTopTagsPromise = this.loadOnlineTopTags()
            if (fetchTopTags) {
                const loadTopTagsPromise = this.loadTopTagsList()
                await Promise.all([loadOnlineTopTagsPromise, loadTopTagsPromise])
            } else {
                await loadOnlineTopTagsPromise
            }
            this.onlineTopTagsFiltered = this.topTagsList.filter((tag) => this.onlineTagsMatchingFilters.includes(tag))
        } else if (fetchTopTags) {
            await this.loadTopTagsList()
            this.onlineTopTagsFiltered = this.topTagsList
        }

        this.setState({ ...this.state, isLoading: false })
    }

    private renderTagsPage(responsivePaging = false): void {
        // Adds all tag options that should go on the current page. The current page tag should always be visible,
        // so it is added to the page if not present
        this.optionsContainer.textContent = ""
        this.updateMaxPageNum(responsivePaging)
        this.createTagOptionsForPage()
        this.addCurrentTagToOptionsIfNotPresent(getPageHashtag())
        if (isTagFilterPaginationActive()) {
            this.updateTagSearch()
        }
    }

    private createTagOptionsForPage(): void {
        // Adds all tag options that fit on this page, which were determined by this.setPagingData()
        this.currentPageTagsList = []
        const [start, stop] = [this.pageMinIndices[this.state.tagPageNum - 1], this.pageMinIndices[this.state.tagPageNum]]
        const filteredTagsList = isTagFilterPaginationActive() ? this.onlineTopTagsFiltered : this.topTagsList

        for (const tag of filteredTagsList.slice(start, stop)) {
            this.createTagFilterOption(tag, false)
            this.currentPageTagsList.push(tag)
        }
    }

    private updateTagSearch(): void {
        // Hides the tag search if there are fewer than 2 pages of results.  Otherwise restricts
        // the set of tags that can be suggested to those present in the current filtered roomlist
        const filteredTagsList = this.onlineTopTagsFiltered
        if (this.state.maxPageNum < 2) {
            this.tagSearch.hideElement()
        } else {
            this.tagSearch.updateOnlineTags(filteredTagsList)
            this.tagSearch.showElement("inline-block")
        }
    }

    private addCurrentTagToOptionsIfNotPresent(currentTag: string | undefined): void {
        // Puts current page tag at the front of the tag options container if it is not already in it.
        // If the options container height changes after adding the current tag, removes tags from the
        // current page until the container returns to the target height or the current tag is the only one left.
        if (currentTag === undefined || this.currentPageTagsList.includes(currentTag)) {
            return
        }

        const targetHeight = this.optionsContainer.offsetHeight
        this.createTagFilterOption(currentTag, true)
        this.currentPageTagsList.push(currentTag)
        this.adjustOptionsContainerHeight(targetHeight)
    }

    private adjustOptionsContainerHeight(targetHeight: number): void {
        while (this.optionsContainer.offsetHeight > targetHeight && this.currentPageTagsList.length > 1) {
            this.optionsContainer.lastElementChild?.remove()
        }
    }

    private setPagingData(setMatchingPageNumber: boolean): void {
        // Finds the max page num and the indices of this.onlineTopTagsFiltered that should be the first and last
        // tags on a given page. The options container and individual tag option widths are variable,
        // so this is done by briefly rendering all options and recording indices where MAX_ROWS_TAG_OPTIONS
        // rows of options are on screen and the container cannot accept any more tags in the last row.
        // If setMatchingPageNumber is true, it will set state.tagPageNum such that the (new pageNum) / (new maxPageNum)
        // is as close to (old pageNum) / (old maxPageNum) as possible

        const allHeights = new Set()
        this.pageMinIndices = [0]
        let idx = 0
        let pageNum = 0
        for (const tag of this.onlineTopTagsFiltered) {
            let lastFilterOption = this.createTagFilterOption(tag, false)
            allHeights.add(lastFilterOption.offsetTop)
            if ((allHeights.size) > MAX_ROWS_TAG_OPTIONS) {
                // We've created an element that ended up on row number MAX_ROWS_TAG_OPTIONS + 1.
                // Remove it and stop appending more
                pageNum += 1

                this.optionsContainer.lastElementChild?.remove()
                if (!isTagFilterPaginationActive()) {
                    // Only find first page cutoff when flag is not active
                    this.pageMinIndices[pageNum] = idx
                    this.optionsContainer.textContent = ""
                    return
                }
                this.optionsContainer.textContent = ""  // Remove all DOM children
                lastFilterOption = this.createTagFilterOption(tag, false)
                this.pageMinIndices[pageNum] = idx
                allHeights.clear()
                allHeights.add(lastFilterOption.offsetTop)
            }
            idx += 1
        }
        const newMaxPageNum = pageNum + 1
        // prevent on initial page load
        if (setMatchingPageNumber && this.state.maxPageNum !== undefined) {
            this.state.tagPageNum = Math.min(1 + Math.round(newMaxPageNum * (this.state.tagPageNum - 1) / this.state.maxPageNum), newMaxPageNum)
        }
        this.optionsContainer.textContent = ""
        this.pageMinIndices[newMaxPageNum] = idx
        this.state.maxPageNum = newMaxPageNum
    }

    private async loadTopTagsList(): Promise<void> {
        // Fetches the top tags list for the current user and saves it to this.topTagsList
        const urlQuery = new URLSearchParams([["count", String(TOP_TAGS_FETCH_COUNT)]])
        const gender = getGenderForTagsApi()
        if (gender !== Gender.All) {
            urlQuery.append("genders", gender)
        }
        const xhr = await getCb(`api/ts/hashtags/top_tags/?${urlQuery.toString()}`)
        const json = new ArgJSONMap(xhr.responseText)
        const topTagsList = json.getStringList("all_tags")
        this.topTagsList = topTagsList
    }

    private getCurrentFilters(): IRoomListAPIParams {
        // Gets the set of currently active roomlist filters.  These filters will be passed to the all-tags
        // api to get the list of tags with non-empty roomlists matching current filters.  Hashtag filter state is
        // removed since the all-tags endpoint will return all tags that have rooms in the current filter set
        const newCategoryFilters = getRoomlistCategoryFilters()
        const newFilters = getRoomlistDynamicFilters()
        const filters = { ...newFilters, ...newCategoryFilters }
        delete filters["hashtags"]
        delete filters["offset"]
        delete filters["limit"]
        return filters
    }

    private async loadOnlineTopTags(): Promise<void> {
        // Fetches all unique hashtags with the rooms matching current roomlist filters
        const filters = this.getCurrentFilters()
        const minTagsVal = (new URLSearchParams(window.location.search)).get("min_tags_count")
        const minTags = (minTagsVal !== null && parseInt(minTagsVal) > 0) ? minTagsVal : "1"
        const queryParams = new URLSearchParams({
            min_count: minTags,
            ...filters as Record<string, string>,
        })

        queryParams.sort()  // Sort params to ensure request URLs with identical filters correspond 1:1
        const fetchUrl = `${ROOMLIST_ALL_TAGS_API_URL}?${queryParams.toString()}`
        const xhr = await getCb(fetchUrl)
        const jsonMap = new ArgJSONMap(xhr.responseText)
        this.onlineTagsMatchingFilters = jsonMap.getStringList("all_tags")
    }

    private createTagFilterOption(tag: string, prepend = false): HTMLAnchorElement {
        // Creates a new tag option and app/pre-pends it to the
        // container element
        const filterOption = new FilterOption({
            testid: "filter-tag-item",
            name: tag,
            labelText: `#${tag}`,
            queryParamValue: tag,
            getHref: () => this.getToggledTagUrl(tag).href,
            optionIsActive: () => this.optionIsActive(tag),
            handleLeftClick: () => this.handleLeftClick(tag),
        })
        if (prepend) {
            this.optionsContainer.prepend(filterOption.element)
        } else {
            this.optionsContainer.append(filterOption.element)
        }
        return filterOption.element
    }

    private handleLeftClick(queryParamValue: string): void {
        if (!isFilterInPathActive()) {
            this.toggleTagInURL(queryParamValue)  // trigger the urlstate listener to update the UI
        } else {
            if ((UrlState.current.state.tags ?? []).includes(queryParamValue)) {
                UrlState.current.clearStateKeys(["tags", "page", "pageb"])
            } else {
                UrlState.current.setPartialState({ tags: [queryParamValue], page: 1, pageb: 1 })
            }
        }
        this.props.onFilterOptionClick()
        addPageAction("HmpgFilterOptionClicked", {
            "category": "tags",
            "value": queryParamValue,
            "active": this.optionIsActive(queryParamValue),
        })
    }

    private optionIsActive(queryParamValue: string): boolean {
        // This method is passed to tag filter options as a prop
        const currentValue = UrlState.current.state["tags"]
        if (currentValue === undefined) {
            return false
        }
        return currentValue.includes(queryParamValue)
    }

    private toggleTagInURL(tag: string): void {
        const newUrl = this.getToggledTagUrl(tag)
        UrlState.current.pushUrl(newUrl)
    }

    private getToggledTagUrl(tag: string): URL {
        if (tag === undefined || tag === getPageHashtag()) {
            const newUrl = new URL(`${window.location.origin}/${getVerboseGenderPath(getCurrentGender())}`)
            newUrl.search = window.location.search
            return newUrl
        } else {
            const path = hashtagUrl(tag, getCurrentGender())
            const newUrl = new URL(window.location.origin + path)
            newUrl.search = window.location.search
            return newUrl
        }
    }
}
