import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { Gender } from "../../../common/genders"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { genderedHomepageUrl } from "../../util/hashtagsUtils"

interface RemoveHashtagState {
    gender: Gender,
    hashtag: string,
}

interface RemoveHashtagProps {
    onTagClick?: (event: MouseEvent) => void,
}

// In the future, we might want to account for search keywords and preserve it in the url:
// https://multimediallc.leankit.com/card/30502079946893
export class RemoveHashtag extends HTMLComponent<HTMLDivElement, RemoveHashtagProps, RemoveHashtagState> {
    private props: RemoveHashtagProps
    constructor(props: RemoveHashtagProps) {
        super(props)
        this.props = props
    }

    protected createElement(props: RemoveHashtagProps): HTMLDivElement {
        return <div className="remove-hashtag-filter"/>
    }

    updateState(): void {
        super.updateState()
        if (this.state.hashtag !== "") {
            this.element.textContent = i18n.roomFilteredByHashtag(this.state.hashtag)
            this.element.appendChild(<a href={genderedHomepageUrl(this.state.gender)} onClick={this.props.onTagClick}>{i18n.removeFilter}</a>)
            this.element.style.display = ""
        } else {
            this.element.style.display = "none"
        }
    }
}
